import React from 'react';
import { useTranslation } from '@/components/useTranslation'


export default function Faq () {
    
    const t = useTranslation();

    const QuestionData = [
        {
            question: t.question_1,
            answer: t.answer_1,
        },
        {
            question: t.question_2,
            answer: t.answer_2,
        },
        {
            question: t.question_3,
            answer: t.answer_3,
        },
        {
            question: t.question_4,
            answer: t.answer_4,
        },
        {
            question: t.question_5,
            answer: t.answer_5,
        },
        {
            question: t.question_6,
            answer: t.answer_6,
        },
        {
            question: t.question_7,
            answer: t.answer_7,
        },

    ]
    
    return (
        <div className='px-4 faq-content md:px-0'>
            <h3 className='pb-10 text-3xl font-bold text-gray-900'>{t.faq_title}</h3>
            <div className="space-y-4 ">
            {QuestionData.map((item, index) => (
                <details
                    key={index}
                    className="group [&_summary::-webkit-details-marker]:hidden"
                >
                    <summary className="flex items-center justify-between p-4 rounded-lg cursor-pointer bg-gray-50">
                    <h4 className="!text-sm md:!text-base font-medium text-gray-900">{item.question}</h4>
                    <svg
                        className="ml-1.5 h-5 w-5 flex-shrink-0 transition duration-300 group-open:-rotate-180"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                    </summary>
                    <div className="px-4 mt-4 !text-sm text-gray-700" dangerouslySetInnerHTML={{__html: item.answer}}></div>
                </details>
                ))}
            </div>
        </div>
    );
};
