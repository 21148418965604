"use client";

import Image from "next/image";
import Link from "next/link";
import { FaLongArrowAltRight, FaPhoneAlt } from "react-icons/fa";
import { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "@/components/useTranslation";

export default function Navbar() {
  const router = useRouter();
  const { locale } = router;
  const t = useTranslation();
  const dropdownRef = useRef(null);

  const [subPageData, setSubPageData] = useState([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    setDropdownOpen(false);
  }, [router.pathname]);


  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const handleCloseDropdown = () => {
    setDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.closest('[data-dropdown-toggle="dropdownNavbar"]')
    ) {
      setDropdownOpen(false);
    }
  };


  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    router.events.on("routeChangeStart", handleCloseDropdown);
    return () => {
      router.events.off("routeChangeStart", handleCloseDropdown);
    };
  }, []);


  // Language Data
  const languageOptions = [
    { locale: "de", text: "Deutsch", imageSrc: "/images/global/flag/germany.png", urls: {
      google: "/de/platform/google-bewertungen-kaufen",
      google_local_guide:"/de/platform/google-local-guide-bewertungen-kaufen",
      trustpilot: "/de/platform/trustpilot-bewertungen-kaufen",
      employer: "/de/platform/arbeitgeber-bewertungen-kaufen",
      google_clear: "/de/platform/google-bewertungen-loschen",
      wunschplattform: "/de/platform/wunschplattform-bewertungen-kaufen"
      }
    },
    { locale: "en", text: "English", imageSrc: "/images/global/flag/united-kingdom.png", urls: {
        google: "/en/platform/buy-google-reviews",
        google_local_guide: "/en/platform/buy-google-local-guide-reviews",
        trustpilot: "/en/platform/buy-trustpilot-reviews",
        employer: "/en/platform/buy-employer-reviews",
        google_clear: "/en/platform/delete-google-reviews",
        wunschplattform: "/en/platform/buy-desired-platform-reviews",
      }
    },
    { locale: "fr", text: "Français", imageSrc: "/images/global/flag/france.png", urls: {
        google: "/fr/platform/acheter-avis-google",
        google_local_guide:"/fr/platform/acheter-avis-google-local-guide",
        trustpilot: "/fr/platform/acheter-avis-trustpilot",
        employer: "/fr/platform/acheter-avis-employeur",
        google_clear: "/fr/platform/supprimer-avis-google",
        wunschplattform: "/fr/platform/acheter-avis-plateforme-souhaitee",
      }
    },
    { locale: "es", text: "Español", imageSrc: "/images/global/flag/spain.png", urls: {
        google: "/es/platform/comprar-opiniones-google",
        google_local_guide:"/es/platform/comprar-resenas-de-google-local-guide",
        trustpilot: "/es/platform/comprar-opiniones-trustpilot",
        employer: "/es/platform/comprar-opiniones-empleador",
        google_clear: "/es/platform/eliminar-opiniones-google",
        wunschplattform: "/es/platform/comprar-resenas-de-plataforma-deseada",
      }
    },
    { locale: "it", text: "Italiano", imageSrc: "/images/global/flag/italy.png", urls: {
        google: "/it/platform/acquista-recensioni-google",
        google_local_guide:"/it/platform/acquista-recensioni-local-guide-di-google",
        trustpilot: "/it/platform/acquista-recensioni-trustpilot",
        employer: "/it/platform/acquista-recensioni-employer",
        google_clear: "/it/platform/elimina-recensioni-google",
        wunschplattform: "/it/platform/acquista-recensioni-piattaforma-desiderata",
      }
    },
  ];

  const [selectedFlag, setSelectedFlag] = useState(languageOptions.find((option) => option.locale === locale));

  useEffect(() => {
    setSelectedFlag(languageOptions.find((option) => option.locale === locale));
  }, [locale]);

  // Subpage Data
  useEffect(() => {
    setSubPageData([
      {
        category: "google",
        title: "Google",
        icon: "/images/global/platform/google-icon.png",
        slug: t.url_google,
      },
      {
        category: "google_local_guide",
        title: "Google Local Guide",
        icon: "/images/global/platform/local-guide-icon.png",
        slug: t.url_google_local_guide,
      },
      {
        category: "trustpilot",
        title: "Trustpilot",
        icon: "/images/global/platform/trustpilot-icon.png",
        slug: t.url_trustpilot,
      },
      {
        category: "employer",
        title: t.employer,
        icon: "/images/global/platform/arbeitgeber-icon.png",
        slug: t.url_arbeitgeber,
      },
      {
        category: "google_clear",
        title: `Google ${t.clear2}`,
        icon: "/images/global/platform/loschen-icon.png",
        slug: t.url_google_clear,
      },
      {
        category: "wunschplattform",
        title: t.wunschplattform,
        icon: "/images/global/platform/wunsch-icon.png",
        slug: t.url_wunschplattform,
      },
    ]);
  }, [t, router.route, locale]);


  return (
    <>
      <nav className="fixed top-0 left-0 z-20 w-full bg-white border-b border-gray-200">
        <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-4 py-2 mx-auto sm:p-4">
          <button
            data-collapse-toggle="navbar-sticky"
            type="button"
            className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-sticky"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <Link href="/" className="flex items-center">
            <Image
              src="/images/global/logo.png"
              width={200}
              height={68}
              className="hidden sm:flex sm:mr-3"
              alt="Funfsterne Logo"
            />
            <Image
              src="/images/global/logo.png"
              width={150}
              height={30}
              className="sm:hidden sm:mr-3"
              alt="Funfsterne Logo"
            />
          </Link>
          <div className="flex lg:order-2 items-center">
            <div className="flex-col items-end hidden pr-3 sm:flex ">
              {/* <span className="text-xs font-bold text-blue-700">
                {t.contact}
              </span> */}
              <Link
                href={"tel:+49123456789"}
                className="text-sm font-bold leading-none"
              >
                +49 30 200 09 515
              </Link>
            </div>

            <Link
              href={router.route === '/agb' || '/datenschutz' || '/impressum' ? "/#preise" : "#preise"}
              className="items-center hidden gap-2 px-4 py-2.5 mr-3 text-sm font-medium text-center text-white bg-blue-700 rounded-full hover:bg-blue-800 md:flex focus:ring-4 focus:outline-none focus:ring-blue-300 lg:mr-0"
            >
              {t.button}
              <FaLongArrowAltRight className="text-sm text-white" />
            </Link>

            <Link
              href="tel:+49 30 200 09 515"
              className="flex items-center justify-center w-8 h-8 bg-blue-700 rounded-full sm:hidden"
            >
              <FaPhoneAlt className="text-white h-full !text-sm" />
            </Link>

            <button
              data-dropdown-toggle="dropdownSmall"
              className="inline-flex items-center pl-4 pr-3 text-sm font-medium text-center text-white rounded-lg"
              type="button"
            >
              <Image
                src={selectedFlag.imageSrc}
                alt={selectedFlag.locale}
                width={24}
                height={24}
              />
            </button>
            <div
              id="dropdownSmall"
              className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow"
            >
              <ul className="py-2 text-sm text-gray-700" aria-labelledby="dropdownSmallButton">
                {languageOptions.map((option) => (
                    <li key={option.locale} className={`flex items-center p-2 hover:bg-gray-100 cursor-pointer`}>

                        <a
                            href="#"
                            className={`flex items-center gap-2 p-1 hover:bg-gray-100 cursor-pointer`}
                            onClick={(e) => {
                              e.preventDefault();
                              const currentSlug = router.asPath.split("/").pop();
                              const currentPage = currentSlug ? subPageData.find((page) => page.slug === currentSlug) : null;

                              if (currentPage && currentPage.category && option.urls) {
                                const category = currentPage.category.toLowerCase();
                                if (option.urls[category]) {
                                  const newSlug = option.urls[category];
                                  router.push(newSlug, newSlug, { locale: option.locale });
                                } else {
                                  console.error(`No URL found for category: ${category}`);
                                }
                              } else {
                                // If the slug is not found in platform, then use the original path
                                router.push(router.asPath, router.asPath, { locale: option.locale });
                              }
                            }}
                        >
                          <Image
                              src={option.imageSrc}
                              alt={option.locale}
                              width={24}
                              height={24} />
                        </a>
                    </li>
                ))}
              </ul>
            </div>
          </div>

          <div  className="items-center justify-between hidden w-full lg:flex lg:w-auto lg:order-1"
                id="navbar-sticky">
            <ul className="flex flex-col p-4 mt-4 font-medium border border-gray-100 rounded-lg lg:p-0 bg-gray-50 lg:flex-row lg:space-x-8 lg:mt-0 lg:border-0 lg:bg-white ">
              <li>
                <Link
                    href="/"
                    className="block py-2 pl-3 pr-4 text-gray-900 bg-blue-700 rounded lg:bg-transparent lg:hover:text-blue-700 lg:p-0"
                    aria-current="page"
                >
                  {t.home}
                </Link>
              </li>
              <li>
              <button
                  id="dropdownNavbarLink"
                  data-dropdown-toggle="dropdownNavbar"
                  className="flex items-center py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700 lg:p-0"
                  onClick={handleDropdownToggle}
                >
                  {t.services}
                  <svg
                    className={`w-5 h-5 ml-1 ${isDropdownOpen ? "transform rotate-180" : ""}`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
                {isDropdownOpen && (
                    <div
                        id="dropdownNavbar"
                        className={`z-10 font-normal bg-white divide-y divide-gray-100 mt-3 rounded-lg shadow w-44 ${isDropdownOpen ? 'block absolute' : 'hidden'}`}
                        ref={dropdownRef}
                    >
                      <ul className="py-2 text-sm text-gray-700 dark:text-gray-400" aria-labelledby="dropdownNavbarLink">
                        {subPageData.map((subData, index) => (
                            <li key={index}>
                              <Link
                                  href={`/platform/${subData.slug}`}
                                  locale={router.locale}
                                  className="block p-3 rounded-lg hover:bg-gray-50"
                              >
                                <div className="flex items-center gap-2 font-normal">
                                  <Image src={subData.icon} alt={subData.category} width={24} height={24} />
                                  {subData.title}
                                </div>
                              </Link>
                            </li>
                        ))}
                      </ul>
                    </div>
                )}
              </li>
              {/*<li>*/}
              {/*  <Link*/}
              {/*    href="/prozess"*/}
              {/*    className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700 lg:p-0"*/}
              {/*  >*/}
              {/*    {t.process}*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li>
                <Link
                    href={router.route === '/agb' || '/datenschutz' || '/impressum' ? "/#preise" : "#preise"}
                    className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700 lg:p-0"
                >
                  {t.price}
                </Link>
              </li>
            </ul>
          </div>

        </div>
      </nav>
    </>
  );
}
