export default {

    "lang": "it",
    "home": "Home",
    "services": "Servizi",
    "process": "Processo",
    "price": "Prezzi",
    "faq": "Domande frequenti",
    "contact": "Contattaci",
    "button": "Prenota ora",
    "button2": "Invia richiesta",
    "loading": "Caricamento",
    "reviews": "Recensioni",
    "review": "Recensione",
    "clear": "Elimina",
    "clear2": "Cancellazione",
    "agb": "Termini e condizioni",
    "privacy_policy": "Informativa sulla privacy",
    "legal": "Legale",
    "impressum": "Impronta",
    "name_surname": "Nome e cognome",
    "buy_reviews": "Acquista recensioni",
    "visit_us": "Vieni a trovarci per i dettagli",
    "buy_clear": "Elimina recensioni",
    "copyright": "Copyright dal ©2011 Tutti i diritti riservati",
    "address": "Kurfürstendamm 14, 10719 Berlin",
    "slogan": "Salviamo la tua reputazione!",
    "email_required": "Email obbligatoria",
    "email_invalid": "Formato email non valido",
    "phone_required": "Telefono obbligatorio",
    "phone_invalid": "Formato numero di telefono non valido",
    "package_id_required": "Pacchetto obbligatorio",
    "phone": "Telefono",
    "order_complete": "Il tuo messaggio è stato ricevuto",
    "order_not_complete": "Non è stato possibile ricevere il tuo messaggio",
    "message": "Altro",
    "email": "Email",
    "kdv": "incl. IVA",
    "positive": "Positivo",
    "negative": "Negativo",
    "garantie": "Garanzia di soddisfazione",
    "loschungData": "Recensione eliminata professionalmente",
    "select_package": "Quale pacchetto ti interessa?\n",
    "basic": "Principiante",
    "business": "Business",
    "pro": "Pro",
    "loschung1": "Fino a",
    "loschung2": "Da",

    "url_google": "acquista-recensioni-google",
    "url_google_local_guide": "acquista-recensioni-local-guide-di-google",
    "url_trustpilot": "acquista-recensioni-trustpilot",
    "url_arbeitgeber": "acquista-recensioni-employer",
    "url_google_clear": "elimina-recensioni-google",
    "url_wunschplattform": "acquista-recensioni-piattaforma-desiderata",

    "employer": "Datore di lavoro",
    "wunschplattform": "Piattaforma desiderata",

    // Home Hero
    "home_hero_title": "<h1>Recensioni Google <br/> Professionali, Discrete <br/> Autentiche.</h1>",
    "home_hero_description": "Il trampolino di lancio per la tua reputazione e il tuo successo! Fidati del potere delle opinioni!",
    "home_hero_list_item1": "Contenuti su misura",
    "home_hero_list_item2": "Turno personale",
    "home_hero_list_item3": "Garanzia di soddisfazione",
    "home_hero_button": "Controlla i prezzi",

    "rating": "Con oltre 35.000 aziende assistite e un tasso di soddisfazione del 98%, abbiamo dimostrato che la soddisfazione del cliente è il nostro obiettivo principale.",

    // Home Step
    "home_step1_title": "Utenti reali, nessun robot.",
    "home_step1_description": "È di vitale importanza ottenere recensioni da utenti reali e stare lontano dalle recensioni di bot programmati. Con noi, riceverai solo recensioni da utenti locali verificati.",
    "home_step1_list_item1": "Oltre 50.000 revisori in tutta la Germania, l'Austria e la Svizzera",
    "home_step1_list_item2": "Nomi tedeschi, account verificati",
    "home_step1_list_item3": "Foto e cronologia naturali",
    "home_step2_title": "Recensioni Google",
    "home_step2_description": "<p>L'importanza delle recensioni Google sta nel fatto che influenzano significativamente la fiducia e il processo decisionale dei clienti.<br/> <br/> Offrono ai clienti la possibilità di vedere le esperienze di altre persone e trarre conclusioni sulla soddisfazione dei clienti e la reputazione di un'azienda.<br/><br/> Le recensioni positive possono aumentare la fiducia e la propensione all'acquisto, mentre le recensioni negative possono scoraggiare potenziali clienti. Le recensioni Google hanno quindi un impatto diretto sul successo e la reputazione di un'azienda nell'era digitale.</p>",
    "home_step3_title": "Perché dovresti acquistare recensioni?</p>",
    "home_step3_description": "<p>Secondo un sondaggio di BrightLocal, l'86% dei consumatori afferma di fidarsi delle recensioni online tanto quanto delle raccomandazioni personali. <br/> <br/> Uno studio della Harvard Business School ha rilevato che un aumento di una stella nella valutazione può portare a un aumento medio del fatturato tra il 5% e il 9%. <br/> <br/> Secondo uno studio di Moz, il numero e la qualità delle recensioni hanno un impatto diretto sulla posizione di un'azienda nei risultati di ricerca locali. <br/> <br/> Un sondaggio di ReviewTrackers mostra che il 94% dei consumatori legge le recensioni negative prima di prendere una decisione d'acquisto. Ciò sottolinea l'importanza di prendere sul serio le recensioni negative e rispondervi.</p>",

    // Price
    "price_description": "Nessun abbonamento, solo una prenotazione singola - flessibile e senza complicazioni per il servizio desiderato.",
    "price_total": "Totale",

    // Services
    "services_title": "Come funziona il processo di pubblicazione di una recensione positiva?",
    "services_item1_title": "Scegli il pacchetto che fa per te",
    "services_item1_description": "Scegli tra i pacchetti specifici per la piattaforma quello più adatto a te",
    "services_item2_title": "Determina i dettagli della tua campagna",
    "services_item2_description": "I nostri operatori ti contatteranno e discuteranno la campagna con te.",
    "services_item3_title": "Contenuti del testo",
    "services_item3_description": "Fornisci i tuoi testi o lascia che ci occupiamo noi della loro creazione, sempre in linea con la tua attività.",
    "services_item4_title": "Foto nelle recensioni",
    "services_item4_description": "Su richiesta, possiamo fornire alcune recensioni con foto. Devi fornirci tu le foto.",
    "services_item5_title": "Inizio dell'assegnazione",
    "services_item5_description": "Una volta organizzato tutto, inizieremo l'assegnazione. Non dovrai preoccuparti di nulla.",
    "services_item6_title": "Report",
    "services_item6_description": "Una volta completata l'assegnazione, riceverai un report dettagliato sulle recensioni assegnate.",

    // Platform

    "platform_title": "Non siamo solo esperti di Google",

    // FAQ
    "faq_title": "Domande frequenti",
    "question_1": "È possibile acquistare recensioni Google?",
    "answer_1": "Funfsterne.de è il leader nel reputation management e offre recensioni professionali e di alta qualità dal <strong>2011</strong>.",
    "question_2": "Quanto costa una recensione Google?",
    "answer_2": "Le <strong>recensioni positive di Google</strong> sono il modo più facile e conveniente per attirare nuovi clienti e aumentare significativamente le vendite. Con soli <strong>650,00€</strong> riceverai <strong>25</strong> recensioni di Google di alta qualità.",
    "question_3": "È possibile acquistare recensioni negative di Google?",
    "answer_3": "Presso Funfsterne, è possibile acquistare solo recensioni positive e richiedere la cancellazione delle recensioni negative. Non offriamo recensioni negative.",
    "question_4": "Quanto costa cancellare una recensione di Google?",
    "answer_4": "La cancellazione di una recensione negativa parte da <strong>175,00€</strong>.",
    "question_5": "Perché Google cancella recensioni positive?",
    "answer_5": "Google può cancellare recensioni positive se violano le loro linee guida. Pertanto, è necessario che le recensioni siano conformi alle politiche di Google. Inoltre, gli algoritmi di Google possono individuare recensioni programmate da bot. È quindi molto importante ottenere recensioni da fornitori professionali e dai loro utenti verificati.",
    "question_6": "Quanto sono affidabili le recensioni di Google?",
    "answer_6": "Le <strong>recensioni di Google</strong> possono essere una fonte di informazioni preziosa per farsi un'idea di un'azienda, di un prodotto o di un servizio. Ecco alcuni fattori che possono influenzare l'affidabilità delle recensioni di Google:\n" +
        "<br/> <br/>" +
        "1 - Numero di recensioni: Un numero maggiore di recensioni di solito offre un quadro più completo e riduce il rischio di opinioni singole che potrebbero non essere rappresentative.\n" +
        "<br/> <br/>" +
        "2 - Qualità delle recensioni: È consigliabile non concentrarsi solo sulla valutazione in stelle. Le recensioni dettagliate offrono una visione più approfondita e possono creare maggiore fiducia.\n" +
        "<br/> <br/>" +
        "3 - Diversità delle recensioni: È utile considerare recensioni da persone diverse con diverse prospettive per ottenere una visione equilibrata.\n" +
        "<br/> <br/>" +
        "4 - Attualità delle recensioni: È importante notare che le prestazioni di un'azienda possono cambiare nel tempo. Pertanto, le recensioni più recenti dovrebbero avere più peso rispetto a quelle più vecchie.",
    "question_7": "Posso recensire Google da solo?",
    "answer_7": "Certamente, anche come utente di Google, puoi lasciare una recensione, ma non puoi recensire il tuo profilo.",

    // Process
    "process_hero_title": "Acquista recensioni <br /> Ora disponibili <br /> Molto semplice.",
    "process_hero_description": "Abbiamo un team di esperti eccezionale che offre servizi di alta qualità e assistenza ai clienti anche in movimento. Offriamo servizi di recensione fin dai primi giorni in cui il commercio online è diventato la principale forma di marketing.",

    "process_step_1_title": "Recensioni Google",
    "process_step_1_description": "Il motore di ricerca di Google aumenta il potenziale delle aziende consentendo loro di apparire in cima all'elenco. <br/> <br/> Quelli che utilizzano il sistema di navigazione quindi considerano le aziende che si trovano nelle posizioni più alte.",

    "process_step_2_title": "Perché dovrei acquistare recensioni su Google Maps?",
    "process_step_2_description": "Il numero di recensioni che un'azienda riceve è sempre importante per i visitatori. Le aziende con poche recensioni e poche stelle sono meno preferite dai visitatori perché non ispirano fiducia. Per questo motivo, il servizio di recensioni di Google Maps è un must per te.",

    "process_step_3_title": "Recensioni Google",
    "process_step_3_description": "Il motore di ricerca di Google aumenta il potenziale delle aziende consentendo loro di apparire in cima all'elenco. <br/> <br/> Quelli che utilizzano il sistema di navigazione quindi considerano le aziende che si trovano nelle posizioni più alte.",

    "process_step_4_title": "Perché dovrei acquistare recensioni su Google Maps?",
    "process_step_4_description": "Il numero di recensioni che un'azienda riceve è sempre importante per i visitatori. Le aziende con poche recensioni e poche stelle sono meno preferite dai visitatori perché non ispirano fiducia. Per questo motivo, il servizio di recensioni di Google Maps è un must per te.",

    "process_services_title": "Come funziona il processo di pubblicazione di una recensione positiva?",
    "process_services_description_1": "<p>Il modo più rapido ed efficace per distinguersi dalla concorrenza è ottenere recensioni positive dai clienti per aumentare la visibilità.</p><br/><p>Secondo gli studi più recenti, l'86% di tutti i clienti sceglie l'azienda con il maggior numero di recensioni e la migliore valutazione media nei risultati di ricerca. <br/>Il modo più rapido ed efficace per distinguersi dalla concorrenza è ottenere recensioni positive dai clienti per aumentare la visibilità.</p><br/><p>Secondo gli studi più recenti, l'86% di tutti i clienti sceglie l'azienda con il maggior numero di recensioni e la migliore valutazione media nei risultati di ricerca.</p>",
    "process_services_description_2": "<p>Il modo più rapido ed efficace per distinguersi dalla concorrenza è ottenere recensioni positive dai clienti per aumentare la visibilità.</p><br/><p>Secondo gli studi più recenti, l'86% di tutti i clienti sceglie l'azienda con il maggior numero di recensioni e la migliore valutazione media nei risultati di ricerca. <br/>Il modo più rapido ed efficace per distinguersi dalla concorrenza è ottenere recensioni positive dai clienti per aumentare la visibilità.</p><br/><p>Secondo gli studi più recenti, l'86% di tutti i clienti sceglie l'azienda con il maggior numero di recensioni e la migliore valutazione media nei risultati di ricerca.</p>",

    // Dynamic Page

    "dynamic_page_hero_description": "Le recensioni positive su @@burasidegisecek@@ sono molto importanti, specialmente per le aziende presenti online. @@burasidegisecek@@ considera le recensioni e i commenti come fattori di ranking per i risultati di ricerca locali. Le aziende con numerose recensioni positive hanno quindi maggiori possibilità di posizionarsi più in alto nei risultati di ricerca. Ciò può portare a una maggiore visibilità e attirare potenzialmente più clienti.",

    "dynamic_page_step1_title": "Perché le recensioni @@burasidegisecek@@ sono importanti?",
    "dynamic_page_step1_description": "<p>L'importanza delle recensioni @@burasidegisecek@@ risiede nel loro influsso significativo sulla fiducia e sulla decisione dei clienti.<br/> <br/>Danno ai clienti la possibilità di vedere le esperienze di altre persone e trarre conclusioni sulla soddisfazione del cliente e sulla reputazione di un'azienda.<br/><br/>Le recensioni positive possono aumentare la fiducia e la propensione all'acquisto, mentre le recensioni negative possono scoraggiare i potenziali clienti. Le recensioni @@burasidegisecek@@ hanno quindi un impatto diretto sul successo e sulla reputazione di un'azienda nell'era digitale.</p>",
    "dynamic_page_step2_title": "Come migliorare le tue recensioni @@burasidegisecek@@?",
    "dynamic_page_step2_description": "Ci sono diverse modalità per migliorare le recensioni @@burasidegisecek@@. Un'opzione è incoraggiare i tuoi clienti a lasciare recensioni e assicurarti che siano positive. Puoi anche usufruire dei servizi professionali di gestione delle recensioni @@burasidegisecek@@ per migliorare la tua reputazione online e rafforzare il tuo brand.",

    "dynamic_page_step3_title": "Come appaiono le recensioni?",
    "dynamic_page_step3_description": "Offriamo una vasta selezione di oltre 50.000 recensori per assicurarti di ottenere recensioni adatte ai tuoi servizi o prodotti. Hai la possibilità di scegliere recensori maschi o femmine, giovani o anziani. Inoltre, riceverai solo recensioni che si adattano al contenuto testuale dei tuoi servizi o prodotti. Ciò garantisce che le recensioni siano pertinenti per la tua azienda e offrano una panoramica completa dell'esperienza del cliente.",

    // Dynamic Deletion
    "dynamic_page_loeschung_hero_title": "Eliminazione delle recensioni Google",
    "dynamic_page_loeschung_hero_description": "È auspicabile evitare le recensioni negative su Google, ma nella pratica non è sempre possibile evitarle completamente. Le recensioni negative possono danneggiare la reputazione di un'azienda. I potenziali clienti potrebbero essere scoraggiati e optare per aziende concorrenti con recensioni migliori. Una cattiva reputazione può avere un impatto negativo sull'immagine e sul successo di un'azienda, poiché molte persone leggono le recensioni prima di prendere unadecisione di acquisto.",
    "dynamic_page_loeschung_step1_title": "Come liberarti delle recensioni negative?",
    "dynamic_page_loeschung_step1_description": "Se le recensioni negative si presentano frequentemente e non vengono affrontate adeguatamente, ciò può portare a una perdita di clienti. Con noi al tuo fianco, non devi più preoccuparti di commenti diffamatori o recensioni negative legate alla concorrenza. Ti aiutiamo a eliminarle.",
    "dynamic_page_loeschung_step2_title": "È possibile eliminare le recensioni negative?",
    "dynamic_page_loeschung_step2_description": "Assolutamente! Con il giusto supporto, è possibile eliminare le recensioni negative. Collaboriamo con uno studio legale molto performante e godiamo di condizioni speciali. Pertanto, pagherai solo alla fine del servizio e solo per le recensioni eliminate con successo. Il nostro tasso di successo è di circa il 97%!",

    "data": [
        {
            "Google": [
                {
                    "title": "Google Principiante",
                    "proBewertung": "25",
                    "price": "550",
                    "features": [
                        "Utenti locali verificati",
                        "Frequenza personalizzata",
                        "Testi personalizzati"
                    ]
                },
                {
                    "title": "Google Business",
                    "proBewertung": "50",
                    "price": "950",
                    "features": [
                        "Utenti locali verificati",
                        "Frequenza personalizzata",
                        "Testi personalizzati",
                        "Dashboard personale"
                    ]
                },
                {
                    "title": "Google Pro",
                    "proBewertung": "100",
                    "price": "1.750",
                    "features": [
                        "Inclusi 10 recensioni di Local Guide",
                        "Utenti locali verificati",
                        "Frequenza personalizzata",
                        "Testi personalizzati",
                        "Dashboard personale",
                        "Divisibile su più profili/posizioni"
                    ]
                }
            ],
            "Google Local Guide": [
                {
                    "title": "Google Local Guide Principiante",
                    "proBewertung": "25",
                    "price": "700",
                    "features": [
                        "Utenti locali verificati",
                        "Frequenza personalizzata",
                        "Testi personalizzati"
                    ]
                },
                {
                    "title": "Google Local Guide Business",
                    "proBewertung": "50",
                    "price": "1.200",
                    "features": [
                        "Utenti locali verificati",
                        "Frequenza personalizzata",
                        "Testi personalizzati",
                        "Dashboard personale"
                    ]
                },
                {
                    "title": "Google Local Guide Pro",
                    "proBewertung": "100",
                    "price": "2.200",
                    "features": [
                        "Utenti locali verificati",
                        "Frequenza personalizzata",
                        "Testi personalizzati",
                        "Dashboard personale",
                        "Divisibile su più profili/posizioni"
                    ]
                }
            ],
            "Trustpilot": [
                {
                    "title": "Trustpilot Principiante",
                    "proBewertung": "25",
                    "price": "700",
                    "features": [
                        "Utenti locali verificati",
                        "Frequenza personalizzata",
                        "Testi personalizzati"
                    ]
                },
                {
                    "title": "Trustpilot Business",
                    "proBewertung": "50",
                    "price": "1.250",
                    "features": [
                        "Utenti locali verificati",
                        "Frequenza personalizzata",
                        "Testi personalizzati",
                        "Dashboard personale"
                    ]
                },
                {
                    "title": "Trustpilot Pro",
                    "proBewertung": "100",
                    "price": "2.250",
                    "features": [
                        "Utenti locali verificati",
                        "Frequenza personalizzata",
                        "Testi personalizzati",
                        "Dashboard personale",
                        "Divisibile su più profili/posizioni"
                    ]
                }
            ],
            "Arbeitgeber": [
                {
                    "title": "Employer Principiante",
                    "proBewertung": "25",
                    "price": "700",
                    "features": [
                        "Utenti locali verificati",
                        "Frequenza personalizzata",
                        "Testi personalizzati"
                    ]
                },
                {


                    "title": "Employer Business",
                    "proBewertung": "50",
                    "price": "1.150",
                    "features": [
                        "Utenti locali verificati",
                        "Frequenza personalizzata",
                        "Testi personalizzati",
                        "Dashboard personale"
                    ]
                },
                {
                    "title": "Employer Pro",
                    "proBewertung": "100",
                    "price": "2.100",
                    "features": [
                        "Utenti locali verificati",
                        "Frequenza personalizzata",
                        "Testi personalizzati",
                        "Dashboard personale",
                        "Divisibile su più profili/posizioni"
                    ]
                }
            ],
            "Google Loschen": [
                // {
                // "title": "Google Cancellazione Principiante",
                // "proBewertung": "5",
                // "loschungData2": "Fino a",
                // "price": "200",
                // "features": [
                //     "Paesi possibili: Germania - Austria - Svizzera",
                //     "Pagamento alla fine del servizio.",
                //     "Pagamento solo per le recensioni cancellate",
                //     "Tasso di cancellazione del 97%",
                //     "Nessun rischio di costo."
                // ]
                // },
                {
                    "title": "Google Cancellazione Business",
                    "titleleft": "Quanti commenti negativi desideri far eliminare?",
                    "leftdesc1": "Ti aiutiamo a rimuovere facilmente e rapidamente recensioni indesiderate e inesatte, poiché non devi tollerare recensioni offensive!",
                    "leftdesc2": "Senza rischio di costi - pagamento solo in caso di successo!",
                    "rangeTitle": "Numero di recensioni da eliminare",
                    "rangeText": "Risparmi",
                    "name": "Recensioni",
                    "garantie": "Garanzia di soddisfazione",
                    "proBwt": "per valutazione cancellata",
                    "proBewertung": "5",
                    "loschungData3": "Da",
                    "price": "300",
                    "features": [
                        "Paesi possibili: Germania - Austria - Svizzera",
                        "Pagamento alla fine del servizio.",
                        "Pagamento solo per le recensioni cancellate",
                        "Tasso di cancellazione del 97%",
                        "Nessun rischio di costo."
                    ]
                },
                // {
                // "title": "Google Cancellazione Pro",
                // "proBewertung": "10",
                // "loschungData3": "Da",
                // "price": "175",
                // "features": [
                //     "Paesi possibili: Germania - Austria - Svizzera",
                //     "Pagamento alla fine del servizio.",
                //     "Pagamento solo per le recensioni cancellate",
                //     "Tasso di cancellazione del 97%",
                //     "Nessun rischio di costo."
                // ]
                // }
            ],
            "Wunschplattform": [
                {
                    "title": "Preferred Platform Principiante",
                    "proBewertung": "25",
                    "price": "800",
                    "features": [
                        "Utenti locali verificati",
                        "Frequenza personalizzata",
                        "Testi personalizzati"
                    ]
                },
                {
                    "title": "Preferred Platform Business",
                    "proBewertung": "50",
                    "price": "1.250",
                    "features": [
                        "Utenti locali verificati",
                        "Frequenza personalizzata",
                        "Testi personalizzati",
                        "Dashboard personale"
                    ]
                },
                {
                    "title": "Preferred Platform Pro",
                    "proBewertung": "100",
                    "price": "2.250",
                    "features": [
                        "Utenti locali verificati",
                        "Frequenza personalizzata",
                        "Testi personalizzati",
                        "Dashboard personale",
                        "Divisibile su più profili/posizioni"
                    ]
                }
            ]
        }
    ],


    // Informazioni legali
    "impressum_step1_title": "Responsabile dei contenuti ai sensi dell'art. 55 comma 2 RStV:",
    "impressum_step1_description": "Funfsterne, indirizzo come sopra",
    "impressum_step2_title": "Risoluzione delle controversie dell'UE",
    "impressum_step2_description": "La Commissione europea mette a disposizione una piattaforma per la risoluzione delle controversie online (ODR): https://ec.europa.eu/consumers/odr. Troverai il nostro indirizzo email sopra nell'informativa legale.",
    "impressum_step3_title": "Risoluzione delle controversie dei consumatori / Organismo universale di mediazione",
    "impressum_step3_description": "Non siamo disposti né obbligati a partecipare a procedure di risoluzione delle controversie dinanzi a un organismo di mediazione per i consumatori.",
    "impressum_step4_title": "Responsabilità dei contenuti",
    "impressum_step4_description": "L'operatore del sito declina ogni responsabilità per le informazioni trasmesse o memorizzate da terzi. L'operatore del sito non è obbligato a monitorare tali informazioni. L'operatore del sito sarà responsabile solo nel caso in cui non ottemperi a una richiesta legittima di cancellazione delle informazioni, qualora le informazioni siano state trasmesse o memorizzate illegalmente da terzi. Le informazioni palesemente illegali saranno prontamente rimosse una volta che ne sarà venuta a conoscenza.",
    "impressum_step5_title": "Responsabilità dei collegamenti",
    "impressum_step5_description": "L'operatore del sito non ha alcun controllo sui collegamenti esterni. Prima di pubblicare i collegamenti su queste pagine, essi sono stati verificati, nella misura del possibile, per garantire la conformità alle disposizioni di legge. L'operatore del sito non è responsabile delle modifiche che possono verificarsi sulle pagine raggiungibili tramite collegamenti esterni. In particolare, non è ragionevolmente possibile esercitare un controllo continuo dei collegamenti esterni per individuare violazioni legali. Se l'operatore del sito viene informato di contenuti illegali accessibili tramite collegamenti esterni, provvederà immediatamente a rimuovere tali collegamenti.",
    "impressum_step6_title": "Diritto d'autore",
    "impressum_step6_description": "Il diritto d'autore di tutti i testi e i contenuti multimediali accessibili sulle pagine dell'operatore spetta all'operatore del sito, salvo diversa indicazione di diritti d'autore separati. Se i diritti d'autore di terzi non sono indicati, ti preghiamo di comunicarcelo tramite email. L'operatore del sito provvederà prontamente a rimuovere tali contenuti. È vietata la riproduzione, la distribuzione e la duplicazione dei contenuti senza l'espresso consenso dell'operatore del sito.",

    // Informativa sulla privacy
    "datenschutz_title1": "Generale",
    "datenschutz_description1": "I tuoi dati personali ai sensi dell'Art. 4 n. 1 del GDPR (ad esempio, saluto, nome, indirizzo, indirizzo email, informazioni di pagamento) saranno trattati da noi solo in conformità alle disposizioni della legge tedesca sulla protezione dei dati e tenendo conto del Regolamento generale sulla protezione dei dati (GDPR) europeo. Le seguenti disposizioni ti informano sulla natura, l'ambito e la finalità della raccolta, del trattamento e dell'uso dei dati personali.",

    "datenschutz_description2": "Il trattamento ai sensi dell'Art. 4 n. 2 del GDPR dei dati personali è lecito ai sensi dell'Art. 6 del GDPR se è soddisfatta una delle seguenti condizioni:",

    "datenschutz_description_list": "<li>L'interessato ha dato il consenso al trattamento dei suoi dati personali per uno o più specifici scopi;</li> <li>il trattamento è necessario per l'esecuzione di un contratto di cui l'interessato è parte o per l'esecuzione di misure precontrattuali richieste dall'interessato;</li><li>il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il titolare del trattamento;</li><li>il trattamento è necessario per proteggere gli interessi vitali dell'interessato o di un'altra persona fisica;</li> <li>il trattamento è necessario per l'esecuzione di un compito di interesse pubblico o per l'esercizio di pubblici poteri di cui è investito il titolare del trattamento;</li> <li>il trattamento è necessario per perseguire un legittimo interesse del titolare del trattamento o di un terzo, a meno che gli interessi o i diritti fondamentali e le libertà dell'interessato, che richiedono la protezione dei dati personali, prevalgano su tali interessi, in particolare se l'interessato è un bambino.</li>",

    "datenschutz_description3": "Il trattamento di categorie particolari di dati personali (ad esempio, dati sulla salute) ai sensi dell'Art. 9, paragrafo 1 del GDPR è lecito in particolare ai sensi dell'Art. 9, paragrafo 2 del GDPR se è soddisfatta una delle seguenti condizioni:",

    "datenschutz_description4": "<li>L'interessato ha espresso il consenso esplicito al trattamento di tali dati personali per uno o più specifici scopi;</li><li>il trattamento è necessario per l'accertamento, l'esercizio o la difesa di un diritto in sede giudiziaria o ogniqualvolta le autorità giudiziarie svolgano le proprie funzioni giurisdizionali.</li>",

    "datenschutz_description5": "Non vengono effettuate decisioni automatizzate o profilazione riguardo ai dati personali ai sensi dell'Art. 22 del GDPR.",

    "datenschutz_description6": "L'operatore garantisce la sicurezza deidati ai sensi dell'Art. 32 del GDPR, tenendo conto del principio di proporzionalità, mediante adeguate misure tecniche. In caso di violazione dei dati, l'autorità di vigilanza competente ai sensi dell'Art. 33 del GDPR e l'interessato ai sensi dell'Art. 34 del GDPR saranno informati.",

    "datenschutz_title2": "Ambito",
    "datenschutz_description7": "Questa informativa sulla privacy si applica solo ai nostri siti web. Se vieni reindirizzato ad altri siti web tramite collegamenti sulle nostre pagine, ti preghiamo di informarti sul rispettivo trattamento dei tuoi dati.",

    "datenschutz_title3": "Cookie",
    "datenschutz_description8": "Questo sito memorizza cookie per riconoscere i suoi visitatori. I dati ottenuti attraverso i cookie non verranno divulgati a terzi. Se desideri comunque impedire la memorizzazione dei cookie, hai la possibilità di disabilitarlo nelle impostazioni del tuo browser.",
    "datenschutz_title4": "Google Webfonts",
    "datenschutz_decsription9": "Questi siti web utilizzano caratteri esterni, Google Fonts. Google Fonts è un servizio fornito da Google Inc. ('Google'). L'integrazione di questi caratteri web avviene tramite una chiamata al server di Google negli Stati Uniti, di solito a un server gestito da Google. Ciò trasmette al server le informazioni su quali dei nostri siti web hai visitato. L'indirizzo IP del browser del visitatore viene anche memorizzato da Google.",
    "datenschutz_decsription10": "Per ulteriori informazioni, consulta l'informativa sulla privacy di Google, che puoi trovare qui:",

    "datenschutz_title5": "Google Analytics",
    "datenschutz_description11": "Questo sito web utilizza Google Analytics, un servizio di analisi web fornito da Google Inc. ('Google'). Google Analytics utilizza dei 'cookie', che sono file di testo collocati sul tuo computer, per aiutare il sito web ad analizzare come gli utenti utilizzano il sito. Le informazioni generate dal cookie sull'utilizzo del sito web (compreso il tuo indirizzo IP) verranno trasmesse a e conservate da Google su server negli Stati Uniti. Tuttavia, se l'anonimizzazione dell'IP è attivata su questo sito web, il tuo indirizzo IP verrà abbreviato da Google all'interno degli Stati membri dell'Unione Europea o in altri paesi aderenti all'Accordo sullo Spazio Economico Europeo. Solo in casi eccezionali l'indirizzo IP completo verrà inviato a un server di Google negli Stati Uniti e abbreviato lì.",

    "datenschutz_description12": "Per conto del gestore di questo sito web, Google utilizzerà queste informazioni allo scopo di valutare il tuo utilizzo del sito web, compilare report sulle attività del sito web per gli operatori del sito web e fornire altri servizi relativi alle attività del sito web e all'utilizzo di Internet. L'indirizzo IP trasmesso dal tuo browser nell'ambito di Google Analytics non sarà associato ad altri dati detenuti da Google.",

    "datenschutz_description13": "Puoi impedire la memorizzazione dei cookie selezionando le apposite impostazioni nel software del tuo browser; tuttavia, ti informiamo che in tal caso potresti non essere in grado di utilizzare tutte le funzionalità complete di questo sito web.",
    "datenschutz_description14": "Puoi inoltre impedire la raccolta dei dati generati dal cookie sull'utilizzo del sito web (compreso il tuo indirizzo IP) e il loro trattamento da parte di Google scaricando e installando il componente aggiuntivo del browser disponibile al seguente link:",

    "datenschutz_description15": "Google Analytics utilizza la funzione di anonimizzazione. È stato stipulato un accordo per il trattamento dei dati con Google Inc.",

    "datenschutz_title6": "Google Tag Manager / Google Ads",
    "datenschutz_description17": "Google Tag Manager è una soluzione che consente ai marketer di gestire i tag dei siti web attraverso un'interfaccia. Lo strumento Tag Manager stesso (che implementa i tag) è un dominio senza cookie e non raccoglie dati personali. Lo strumento attiva altri tag che possono invece raccogliere dati. Google Tag Manager non accede a tali dati. Se è stata eseguita una disattivazione a livello di dominio o di cookie, essa rimarrà in vigore per tutti i tag di tracciamento implementati con Google Tag Manager.",
    "datenschutz_description18": "Diversi regolamenti sulla privacy si applicano a questi dati da parte dell'azienda Google. Per ulteriori informazioni sull'informativa sulla privacy di Google, visita:",

    "datenschutz_description19": "È stato stipulato un accordo per il trattamento dei dati con Google Inc.",

    "datenschutz_title7": "Paypal",
    "datenschutz_description20": "Nel caso di pagamento tramite PayPal, carta di credito tramite PayPal, addebito diretto tramite PayPal o 'acquisto su fattura' o pagamenti a rate tramite PayPal, trasferiamo i tuoi dati di pagamento a PayPal (Europe) S.à r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Lussemburgo ('PayPal') per l'elaborazione dei pagamenti. PayPal si riserva il diritto di effettuare una verifica del credito per i metodi di pagamento come carta di credito tramite PayPal, addebito diretto tramite PayPal, 'acquisto su fattura' tramite PayPal o pagamenti a rate tramite PayPal. Il risultato della verifica del credito sulla probabilità statistica di insolvenza viene utilizzato da PayPal per decidere se fornire il rispettivo metodo di pagamento. La verifica del credito può coinvolgere valori di probabilità (chiamati valori di punteggio). Se i valori di punteggio sono inclusi nel risultato della verifica del credito, si basano su una procedura matematico-statistica riconosciuta scientificamente. Il calcolo dei valori di punteggio include, tra l'altro, dati relativi all'indirizzo.",

    "datenschutz_decsription20_1": "Per ulteriori informazioni relative alla privacy, compresi gli uffici di informazione creditizia utilizzati, consulta l'Informativa sullaprivacy di PayPal al seguente link:",

    "datenschutz_title8": "Sicurezza dei tuoi dati / Crittografia SSL",
    "datenschutz_description21": "In conformità alle disposizioni legali di cui all'Art. 13 (7) del TMG, questo sito utilizza la crittografia SSL, riconoscibile dal simbolo del lucchetto nella barra degli indirizzi del tuo browser. Quando la crittografia SSL è attivata, i dati trasmessi non possono essere letti da terzi.",

    "datenschutz_description22": "Nella maggior parte dei casi, si tratta di una crittografia a 256 bit. Se il tuo browser non supporta la crittografia a 256 bit, utilizziamo invece la tecnologia a 128 bit v3. Puoi riconoscere se una singola pagina del nostro sito web viene trasmessa in forma crittografata dall'icona del lucchetto o della chiave chiusa nella barra di stato inferiore del tuo browser.",

    "datenschutz_description23": "Inoltre, utilizziamo adeguate misure di sicurezza tecniche e organizzative (TOM) per proteggere i tuoi dati da manipolazioni accidentali o intenzionali, perdite parziali o totali, distruzioni o accessi non autorizzati da parte di terzi. Le nostre misure di sicurezza vengono continuamente migliorate in linea con gli sviluppi tecnologici.",

    "datenschutz_title9": "Diritti dell'interessato",

    "datenschutz_description24": "Hai il diritto di richiedere informazioni sui dati personali che conserviamo su di te in qualsiasi momento e gratuitamente. I tuoi diritti includono anche la conferma, la correzione, la limitazione, il blocco e la cancellazione di tali dati, nonché la fornitura di una copia dei dati in un formato idoneo alla trasmissione, nonché il revocare il consenso dato e l'opposizione. Le disposizioni legali di conservazione dei dati rimangono invariate.",

    "datenschutz_description25": "I tuoi diritti derivano in particolare dalle seguenti disposizioni del GDPR:",

    "datenschutz_list26": "<li>Articolo 7, paragrafo 3 - Diritto di revocare il consenso</li><li>Articolo 12 - Informazioni trasparenti, comunicazione e modalità per l'esercizio dei diritti dell'interessato</li><li>Articolo 13 - Informazioni da fornire quando si raccolgono dati personali dall'interessato</li><li>Articolo 14 - Informazioni da fornire quando i dati personali non sono stati ottenuti direttamente dall'interessato</li><li>Articolo 15 - Diritto di accesso dell'interessato, diritto di conferma e comunicazione dei dati personali</li><li>Articolo 16 - Diritto di rettifica</li><li>Articolo 17 - Diritto all'oblio ('diritto di essere dimenticato')</li><li>Articolo 18 - Diritto alla limitazione del trattamento</li><li>Articolo 19 - Obbligo di notifica in caso di rettifica o cancellazione dei dati personali o limitazione del trattamento</li><li>Articolo 20 - Diritto alla portabilità dei dati</li><li>Articolo 21 - Diritto di opposizione</li><li>Articolo 22 - Diritto di non essere sottoposto a decisioni automatizzate, compresa la profilazione</li><li>Articolo 77 - Diritto di presentare reclamo a un'autorità di controllo</li>",



    // AGB

    "agb_title1": "1. GENERALE",
    "agb_description1": "Funfsterne opera su Internet, tra gli altri, sotto il dominio Funfsterne.de, un'applicazione software utilizzabile tramite Internet (di seguito: 'Servizio') che supporta gli imprenditori nelle loro attività sulle piattaforme commerciali. Il fornitore del Servizio è Funfsterne, Kurfürstendamm 14, 10719 Berlin, Germania (di seguito: 'Funfsterne'). Queste condizioni generali si applicano a tutti i rapporti commerciali, inclusi quelli futuri, tra l'utente e Funfsterne. Le condizioni generali dell'utente (ad esempio, termini di fornitura o di acquisto) diverse, in conflitto o supplementari non diventano parte integrante del contratto, a meno che la loro validità non sia espressamente concordata al momento della conclusione del contratto. Si fa espressamente obiezione a qualsiasi riferimento generale alle condizioni generali dell'utente.",

    "agb_title2": "2. CONCLUSIONE DEL CONTRATTO",
    "agb_description2": "L'oggetto del contratto di utilizzo è la fornitura del Servizio per l'utilizzo delle funzionalità all'utente. L'ambito delle prestazioni, in particolare la funzionalità tecnica e la portata dei servizi offerti, dipende dal piano tariffario scelto durante la registrazione. La portata delle funzioni di un piano tariffario è indicata nella descrizione delle prestazioni. Inoltre, Funfsterne si riserva la possibilità di offrire servizi aggiuntivi che vanno oltre il piano tariffario selezionato. I costi e l'ambito dei singoli servizi aggiuntivi sono indicati anche nella descrizione delle prestazioni e nell'attuale listino prezzi. Per utilizzare il Servizio, l'utente deve disporre di un accesso a Internet, di un dispositivo abilitato a Internet e di un browser Internet aggiornato, le cui spese sono a suo carico. L'oggetto del contratto non è il raggiungimento di un determinato successo di vendita sulle piattaforme commerciali. Funfsterne offre solo la possibilità di utilizzare determinati servizi, la cui implementazione di successo è unicamente responsabilità dell'utente.",

    "agb_title3": "3. CONCLUSIONE DEL CONTRATTO",
    "agb_description3": "Inoltre, può verificare i servizi selezionati e modificarli selezionando le caselle corrispondenti. Facendo clic sul pulsante appropriato, l'utente presenta un'offerta vincolante per concludere un contratto di utilizzo. Funfsterne accetta l'offerta inviando una conferma d'ordine che include anche i dati di accesso ai servizi. Sia la lingua tedesca che, a seconda della piattaforma utilizzata, la lingua inglese sono disponibili per la conclusione del contratto. Il testo del contratto è archiviato da Funfsterne, ma non è accessibile al cliente.",

    "agb_title4": "4. FORNITURA DEL SERVIZIO",
    "agb_description4": "Il Servizio viene fornito all'utente tramite attivazione da parte di Funfsterne. Il punto di consegna per la fornitura del Servizio è l'uscita del router del centro dati di Funfsterne. Funfsterne si impegna a garantire la disponibilità del Servizio al punto di consegna al 97% in media annuale.",

    "agb_title5": "5. DIRITTO D'USO, DIRITTI",
    "agb_description5": "Funfsterne concede all'utente il diritto semplice, non trasferibile e non sublicenziabile di utilizzare il Servizio per scopi aziendali durante il periodo di validità del contratto. All'utente non sono concessi altri diritti. In particolare, l'utente non è autorizzato a concedere il Servizio a terzi né a fornire servizi a terzi tramite il Servizio. Questo divieto non si applica ai fornitori di servizi che agiscono direttamente per conto del cliente. Se, durante il periodo di validità del contratto di utilizzo, vengono creati sul server di Funfsterne un database o più database o un'opera o più opere di database, tutti i diritti su di essi spettano all'utente. L'utente rimane proprietario dei database o delle opere di database anche dopo la scadenza del contratto.",

    "agb_description6": "impegna a garantire la copertura del conto. I costi derivanti da mancato pagamento o rimessa di un ordine di pagamento sono a carico del cliente fino a quando il mancato pagamento o la rimessa non siano dovuti a Funfsterne. Inoltre, Funfsterne addebita una commissione di gestione amministrativa di 10 € netti. Funfsterne emette le fatture in formato elettronico. Vengono inviate tramite e-mail. Inoltre, Funfsterne può consentire all'utente di scaricare i documenti di fatturazione in formato digitale dal proprio account utente. Se l'utente desidera anche una spedizione postale, Funfsterne ha il diritto di richiedere una somma forfettaria in base al listino prezzi. Funfsterne agisce come piattaforma di intermediazione tra imprenditori e tester. In questa funzione, Funfsterne gestisce il credito dell'imprenditore caricato sulla piattaforma per pagare i tester nelle campagne gestite. Il credito depositato su Funfsterne deve essere utilizzato per i servizi forniti dallo strumento (pagamenti ai tester, acquisto di supplementi gestiti/totalmente gestiti). Non è possibile prelevare il credito Funfsterne in contanti o trasferirlo su un conto bancario.",

    "agb_title7": "7. DOVERI E RESPONSABILITÀ DELL'UTENTE",
    "agb_description7": "L'utente seleziona le credenziali di accesso per l'utilizzo del Servizio. L'utente è tenuto a mantenere segrete le proprie credenziali di accesso e a informare immediatamente Funfsterne della perdita o dell'uso non autorizzato delle credenziali da parte di terzi. All'utente non è consentito fornire le credenziali di accesso a terzi. Funfsterne ha il diritto di bloccare le credenziali di accesso se sussiste il sospetto di un utilizzo non autorizzato o di un abuso dei dati. L'utente può utilizzare il Servizio solo per scopi aziendali propri. L'utente si impegna a non utilizzare i servizi per promuovere offerte concorrenti con Funfsterne né a raccogliere dati o informazioni di contatto di terzi all'interno dei servizi e a contattare o stabilire relazioni contrattuali con tali terzi al di fuori dei servizi. Se l'utente riscontra un malfunzionamento o un'utilizzo improprio del Servizio, è tenuto a informare immediatamente Funfsterne in forma scritta.",

    "agb_title8": "8. PROTEZIONE DEI DATI",
    "agb_description8": "Le parti rispetteranno le disposizioni applicabili in materia di protezione dei dati, in particolare le disposizioni tedesche in materia di protezione dei dati. Funfsterne è autorizzata ad analizzare in forma anonima i dati degli utenti per fini statistici e per fini propri, compresi scopi pubblicitari. Ciò serve in particolare allo sviluppo dei servizi.",
    "agb_title9": "9. RESPONSABILITÀ",
    "agb_description9": "Funfsterne è responsabile delle valutazioni presentate e assicura di presentare tutte le valutazioni prenotate. Dopo l'invio del rapporto finale, Funfsterne garantisce l'esistenza delle valutazioni per 90 giorni. Al termine del periodo, Funfsterne non è più responsabile e l'ordine è definitivo.",

    "agb_title10": "10. DURATA, RECESSO",
    "agb_description10": "La durata del contratto per l'utilizzo a pagamento del Servizio inizia con l'attivazione da parte di Funfsterne e si estende di mese in mese per un periodo indeterminato. Se l'utente sceglie un intervallo di pagamento diverso, la durata del contratto si estende al periodo di pagamento scelto. La durata di un contratto per l'utilizzo a pagamento del Servizio si rinnova automaticamente per il rispettivo periodo contrattuale se non viene tempestivamente disdetto. Se i servizi utilizzati, in particolare i servizi aggiuntivi, superano la durata del contratto, il contratto non può essere rescisso prima della scadenza dei servizi aggiuntivi. La disdetta, compresa quella di Funfsterne, deve essere almeno in forma scritta; inoltre, è previsto un preavviso di due settimane alla scadenza del periodo di validità. Rimane ferma la possibilità di rescissione immediata per giusta causa. Anche questa rescissione deve essere in forma scritta. Se l'utente interrompe unilateralmente l'utilizzo del Servizio prima della scadenza del periodo di disdetta, resta comunque obbligato a pagare il corrispettivo. Se il cliente è in mora con il pagamento delle spese mensili o con altre obbligazioni di pagamento non solo in misura insignificante, Funfsterne può revocare l'autorizzazione all'utilizzo dei servizi fino al completo adempimento dell'obbligazione di pagamento e/o rifiutare la fruizione di ulteriori servizi. Il diritto di recesso per giusta causa rimane inalterato insieme al diritto di revocare l'autorizzazione all'utilizzo.",

    "agb_title11": "11. MODIFICHE DELLE PRESENTI CGU, TRASFERIMENTO DI DIRITTI",
    "agb_description11": "Funfsterne ha il diritto di modificare le disposizioni relative alle prestazioni da fornire a sua discrezione, tenendo conto delle esigenze tecniche e delle condizioni di mercato, nella misura in cui ciò sia ragionevolmente accettabile per l'utente. Le modifiche alle presenti condizioni generali saranno pubblicate nel Servizio. L'utente sarà informato in forma scritta delle modifiche alle CGU che non rientrano nell'ambito del paragrafo 1. Le modifiche diventano efficaci a meno che l'utente non presenti una contestazione scritta entro 14 giorni dalla ricezione della comunicazione delle modifiche. L'utente sarà informato del significato del suo silenzio insieme alla comunicazione delle modifiche. Se l'utente contesta la modifica, Funfsterne ha il diritto di risolvere il contratto alla scadenza del periodo di validità. Se Funfsterne non fa uso di tale diritto di risoluzione, il contratto continuerà ad essere valido secondo le condizioni in vigore fino a quel momento.",

    "agb_title12": "12. DISPOSIZIONI FINALI",
    "agb_description12": "Si applica la legge della Repubblica Federale di Germania. Le disposizioni della Convenzione delle Nazioni Unite sul contratto di vendita internazionale di beni non si applicano. Se l'utente è un commerciante, una persona giuridica di diritto pubblico o un ente pubblico speciale, il foro competente per tutte le controversie derivanti dal presente contratto è il luogo di residenza di Funfsterne. Lo stesso si applica se l'utente non ha una giurisdizione generale in Germania o se il suo domicilio o la sua residenza abituale al momento della presentazione della denuncia non sono noti.",


    "discount_modal_title": "Offerta speciale: Fino al 30% di sconto per i nuovi clienti!",

    "discount_modal_paragraph_0": "Cari futuri clienti,",

    "discount_modal_paragraph_1": "La nostra reputazione è stata costruita attraverso anni di duro lavoro, impegno per la qualità e feedback continuo dai nostri stimati clienti. La fiducia non viene facilmente; si guadagna. Ed è proprio per questo motivo che ora facciamo un passo coraggioso:",

    "discount_modal_paragraph_2": "Ridistribuiamo l'intero nostro profitto attraverso questo sconto, solo per assicurarci che possiate conoscerci e sperimentare i nostri servizi eccezionali di persona.",

    "discount_modal_paragraph_3": "Immaginate la sensazione di aver trovato la migliore offerta sul mercato, l'euforia di un affare straordinario, e la certezza di aver preso la giusta decisione. Vi diamo lo stimolo per provare i nostri servizi, sicuri che non rimarrete delusi.",

    "discount_modal_paragraph_4": "Approfittate di questa offerta esclusiva e risparmiate subito",

    "discount_modal_list_item_1": "20% sul Pacchetto Base",
    "discount_modal_list_item_2": "25% sul Pacchetto Business",
    "discount_modal_list_item_3": "30% sul Pacchetto Pro",

    "discount_modal_paragraph_5": "Scoprite da soli perché così tanti clienti ci fidano e ci amano. Per usufruire dello sconto, utilizzate il seguente codice quando compilate il modulo di contatto",

    "discount_modal_paragraph_6": "Codice sconto: #NK2023",

}